import * as testIds from '@chegg-wt/core/components/UpgradeAdFrame/testIds'
import { CITATION_LIST_NAME_LOWER_PLURAL } from '@chegg-wt/core/constants/citations'
import { UpgradePromotionBoxCopy } from '@chegg-wt/core/types/copy'
import { UpgradeCopy } from '@chegg-wt/core/types/core'

const upgradeCopy: UpgradeCopy = {
  button: 'Upgrade Now',
  header: ['Did you cite everything?', 'Know for sure with', 'Cite This For Me Premium!'],
  body: [
    { bullet: 'Get 5 plagiarism checks', dataTestId: testIds.UPGRADE_BULLET_PLAGIARIAM },
    {
      bullet: 'Create unlimited references',
      dataTestId: testIds.UPGRADE_BULLET_UNLIM_REFS,
    },
    {
      bullet: `Save your ${CITATION_LIST_NAME_LOWER_PLURAL}`,
      dataTestId: testIds.UPGRADE_BULLET_SAVEBIB,
    },
  ],
}

export const flankerDesignUpgradeCopy: UpgradeCopy = {
  button: 'Learn More',
  header: 'All of our writing tools, none of the ads',
  body: [
    {
      bullet: 'No ads',
      dataTestId: testIds.UPGRADE_NO_ADS,
    },
    {
      bullet: 'MLA and APA citation styles + 7,000 more',
      dataTestId: testIds.UPGRADE_BULLET_STYLES,
    },
    {
      bullet: 'Scan your paper for plagiarism mistakes',
      dataTestId: testIds.UPGRADE_BULLET_PLAGIARIAM,
    },
    {
      bullet: 'Check for 400+ advanced grammar errors',
      dataTestId: testIds.UPGRADE_BULLET_GRAMMAR,
    },
    {
      bullet: 'Create in-text citations and save them',
      dataTestId: testIds.UPGRADE_BULLET_INTEXT,
    },
  ],
  hideTermsAndConditions: true,
}

export const upgradePromotionCopy: UpgradePromotionBoxCopy = {
  buttonText: 'Learn More',
  mainHeading: 'Cite smarter, worry less with Cite This For Me Premium',
  subHeading: 'Upgrade to save your work, check with plagiarism, and more!',
}

export default upgradeCopy
